import React from "react"
import { Link, graphql } from "gatsby"
import _ from "lodash"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import PageHeader from "../components/headers/page-header"
import SectionHeader from "../components/headers/section-header"
import ContentBox from "../components/content-box"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackward } from "@fortawesome/pro-solid-svg-icons"
import TapeLabelHeader from "../components/headers/tape-label-header"

class BlogIndex extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const posts = data.allMarkdownRemark.edges

    console.log(pageContext)

    return (
      <Layout location={this.props.location}>
        <SEO
          title={'Blog'}
          description={`Comic, movie, documentary and book reviews related to politics, obscure pop-culture, Conspiracy Theories and the Occult.`}
        />

        <PageHeader>Blog</PageHeader>

        <div css={css`
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
				`}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (

              <div key={node.fields.slug} css={css`
							flex: 0 100%;
							> div {
							align-self: stretch;
								margin-bottom:40px;
							}
							
							@media screen and (min-width: 960px) {
								display: flex;
								flex: 0 50%;
								> div {
									margin: 20px;
								}
							}
						`}>
                <ContentBox
                  fluid={(node.frontmatter.featuredImg && node.frontmatter.featuredImg.childImageSharp.fluid) || (node.frontmatter.image && node.frontmatter.image.childImageSharp.fluid)}
                  link={node.fields.slug}
                >
                  <Link to={node.fields.slug} title={title}>
                    <SectionHeader align={"left"}>{title}</SectionHeader>
                  </Link>
                  <small
                  >{node.frontmatter.date}</small>
                  <p
                    dangerouslySetInnerHTML={{ __html: node.frontmatter.description || node.excerpt }}/>
                  <div>
                    {node.frontmatter.categories.map(category => {
                      return (
                        <Link key={category} to={`/categories/${_.kebabCase(category)}`} title={category}><kbd>{category}</kbd></Link>)
                    })}
                  </div>
                </ContentBox>
              </div>
            )
          })}
        </div>

        <footer css={css`
              a {
                color: #101010;
                &:hover {
                  color: #101010;
                  text-decoration: underline;
                  h1 { text-decoration: underline; }
                }
              }
            `}>
          <nav>
            <ul
              css={css`
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  list-style: none;
                  padding: 0;
                `}
            >
              <li>
                {pageContext.previousPagePath && (
                  <Link to={pageContext.previousPagePath} rel="next" title={'newer posts'}>
                    <div css={css`display:flex;flex-direction:row;flex-wrap: nowrap;`}>
                      <div css={css`margin-right:20px;align-self: center;font-size:24px;`}><FontAwesomeIcon icon={faBackward} /></div>
                      <div css={css`display:flex;flex-direction: column; flex-wrap: nowrap; text-align: left; h1 { margin: 0; }`}>
                        <TapeLabelHeader>Newer Posts</TapeLabelHeader>
                      </div>
                    </div>
                  </Link>
                )}
              </li>
              <li>
                {pageContext.nextPagePath && (
                  <Link to={pageContext.nextPagePath} rel="prev" title={'older posts'}>
                    <div css={css`display:flex;flex-direction:row;flex-wrap: nowrap;`}>
                      <div css={css`display:flex;flex-direction: column; flex-wrap: nowrap; text-align: left;`}>
                        <TapeLabelHeader>Older Posts</TapeLabelHeader>
                      </div>
                      <div css={css`margin-left:20px;align-self: center;font-size:24px;`}><FontAwesomeIcon icon={faBackward} flip={'horizontal'} /></div>
                    </div>
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </footer>

      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {frontmatter: {type: {eq: "blog"}}}
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        image {
                            childImageSharp {
                                fluid(maxWidth: 200) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        categories
                    }
                }
            }
        }
    }
`
